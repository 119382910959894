import React from 'react'

// eslint-disable-next-line
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

export default function MoveTableRow({ move }) {
  return (
    <tr>
      <th tw="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
        {move.name}
      </th>
      <td tw="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {move.gen}
      </td>
      <td tw="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {move.level}
      </td>
    </tr>
  )
}
