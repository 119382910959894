import React, { useState } from 'react'

import Navbar from '../components/Navbar'
import PokemonPicker from './PokemonPicker'
import PokemonMixer from './PokemonMixer'
import Footer from '../components/Footer'

/** @jsxImportSource @emotion/react */

import { GlobalStyles } from 'twin.macro'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

// eslint-disable-next-line
const searchClient = algoliasearch(
  '1HJ9BUMEEB',
  'd833f4518868ed11886c263810a51133'
)

export default function Profile() {
  const [activeIndex, setActiveIndex] = useState(null)
  const [pokemons, setPokemons] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  return (
    <>
      <GlobalStyles />
      <Navbar transparent />
      <main tw="h-full bg-gray-300">
        <section tw="relative block" style={{ height: '350px' }}>
          <div
            tw="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://veekun.com/static/pokedex/downloads/sugimori.png')"
            }}
          >
            <span
              id="blackOverlay"
              tw="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            tw="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              tw="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                tw="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <InstantSearch indexName="dna_pokemons" searchClient={searchClient}>
          <Configure hitsPerPage={12} analytics={false} distinct />
          <PokemonPicker
            pokemons={pokemons}
            setPokemons={setPokemons}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            setSelectedTypes={setSelectedTypes}
          />
        </InstantSearch>
        <PokemonMixer pokemons={pokemons} selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes}/>
      </main>
      <Footer />
    </>
  )
}
