import React from 'react'

import { allTypes, getEffectiveness } from '../tools/typeManagement'
import { typeToHex } from '../tools/typeColour'

const effectivenessTohex = (effectiveness) => {
  if (effectiveness > 2) {
    return '#059669'
  }
  if (effectiveness > 1) {
    return '#34D399'
  }
  if (effectiveness === 1) {
    return '#CACACA'
  }
  if (effectiveness < 0.5) {
    return '#DC2626'
  }
  if (effectiveness < 1) {
    return '#F87171'
  }

  return 'black'
}

// eslint-disable-next-line
import tw, { css, styled } from 'twin.macro'
/** @jsxImportSource @emotion/react */

const TypeWrapper = styled.span(({ type }) => [
  tw` flex-grow-0  text-xs font-semibold inline-block py-2 px-4 uppercase text-center`,
  css`
    color: white;
    background-color: ${typeToHex(type)};
    transition: all 0.3s ease;
    width: 80px;
  `
])

const EffectivenessWrapper = styled.span(({ effectiveness }) => [
  tw` flex-grow-0 text-xs font-semibold inline-block py-2 px-4 text-white uppercase text-center`,
  css`
    color: black;
    width: 60px;
    background-color: ${effectivenessTohex(effectiveness)};
  `
])

export default function TypeChart({ selectedTypes }) {
  return (
    <div tw="mr-8 mb-8">
      {allTypes.map((type, index) => {
        const effectiveness = getEffectiveness(
          type,
          selectedTypes.map((e) => e.label)
        )
        return (
          <div tw="" key={`TypeChart${index}`}>
            <TypeWrapper type={type}>{type}</TypeWrapper>
            <EffectivenessWrapper effectiveness={effectiveness}>
              {effectiveness}
            </EffectivenessWrapper>
          </div>
        )
      })}
    </div>
  )
}
