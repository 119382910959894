import React from 'react'

// eslint-disable-next-line
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

export default function Navbar() {
  return (
    <nav
      tw={
        'top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3'
      }
    >
      <div tw="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div tw="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <span
            tw={
              'text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase'
            }
          >
            Pokemon builder
          </span>
        </div>
      </div>
    </nav>
  )
}
