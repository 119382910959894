import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import Builder from "./views/Builder";
import reportWebVitals from './reportWebVitals';

import "./main.css";

import "@fortawesome/fontawesome-free/css/all.min.css";

ReactGA.initialize('G-WR5ZFTM470');

ReactDOM.render(
  <React.StrictMode>
    <Builder />
  </React.StrictMode>,
  document.getElementById('root')
);

function sendToGoogleAnalytics({name, value, id}) {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? value  * 1000 : value ),
    label: id,
    nonInteraction: true,
    transport: 'beacon',
  });
}


reportWebVitals(sendToGoogleAnalytics);
