import React, {useState} from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'

// eslint-disable-next-line
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

const AutocompleteSearch = ({
  hits,
  currentRefinement,
  refine,
  onSearchClick,
  numLeft
}) => {
  const [isFirstSearch, setIsFirstSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  return (
  <>
    <span tw="block uppercase text-gray-700 text-xs font-bold mb-2">
      Add a pokémon to your mix ({numLeft} left)
    </span>
    <div tw="relative w-full mt-2 mb-4 bg-gray-300">
      <input
        type="search"
        placeholder="Search by name, ability, dex number.."
        tw="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm border border-gray-400  focus:border-blue-400  outline-none focus:outline-none focus:shadow-md w-full pr-10"
        value={currentRefinement}
        onChange={(event) => {
          if (!isFirstSearch) {
            setIsFirstSearch(true);
          }
          setSearchValue(event.currentTarget.value)
          refine(event.currentTarget.value);
        }}
      />
      <span tw="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
        <i className="fas fa-search"></i>
      </span>
    </div>

    <div tw="flex flex-wrap items-start">
      {searchValue.length > 0 && isFirstSearch && hits.map((hit) => (
        <button
          key={hit.objectID}
          onClick={() => onSearchClick(hit)}
          tw=" flex items-center bg-gray-100 text-black focus:text-blue-500 hover:text-blue-500 active:bg-white font-bold uppercase text-sm pl-10 pr-4 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-3"
          type="button"
          style={{
            transition: 'all .15s ease',
            backgroundImage: `url(${hit.thumbnail})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '.4em .2em',
            backgroundSize: 'auto 2em'
          }}
        >
          {hit.name}
        </button>
      ))}
    </div>
  </>
)}

export default connectAutoComplete(AutocompleteSearch)
