import React from 'react'

// eslint-disable-next-line
import tw, { styled } from 'twin.macro'
/** @jsxImportSource @emotion/react */

export default function AllAbilities({ pokemons }) {
  const allAbilities = []
  pokemons.forEach((pkmn) => {
    pkmn.abilities.forEach((ability) => {
      allAbilities.push(ability)
    })
  })

  const uniqAbilities = []
  allAbilities.filter((item) => {
    const i = uniqAbilities.findIndex((x) => x.name === item.name)
    if (i <= -1) {
      uniqAbilities.push(item)
    }
    return null
  })
  return (
    <table tw="items-center w-full md:w-4/12 bg-transparent border-collapse">
      <thead>
        <tr>
          <th tw="px-4 bg-gray-200 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Ability
          </th>
          <th tw="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        {uniqAbilities.map((ability, index) => (
          <tr key={`allAbilities${index}`}>
            <th tw="border-t border-gray-400 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              {ability.name}
            </th>
            <td tw="border-t border-gray-400 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {ability.description}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
