const typeChart = {
	Normal: {
		damageTaken: {
			Normal: 1,
			Fighting: 2,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 1,
			Bug: 1,
			Ghost: 0.25,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 1,
			Ice: 1,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Fighting: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 2,
			Poison: 1,
			Ground: 1,
			Rock: 0.5,
			Bug: 0.5,
			Ghost: 1,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 2,
			Ice: 1,
			Dragon: 1,
			Dark: 0.5,
			Fairy: 2,
		},
    },
    Flying: {
		damageTaken: {
			Normal: 1,
			Fighting: 0.5,
			Flying: 1,
			Poison: 1,
			Ground: 0.25,
			Rock: 2,
			Bug: 0.5,
			Ghost: 1,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 0.5,
			Electric: 2,
			Psychic: 1,
			Ice: 2,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Poison: {
		damageTaken: {
			Normal: 1,
			Fighting: 0.5,
			Flying: 1,
			Poison: 0.5,
			Ground: 2,
			Rock: 1,
			Bug: 0.5,
			Ghost: 1,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 0.5,
			Electric: 1,
			Psychic: 2,
			Ice: 1,
			Dragon: 1,
			Dark: 1,
			Fairy: 0.5,
		},
    },
    Ground: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 1,
			Poison: 0.5,
			Ground: 1,
			Rock: 0.5,
			Bug: 1,
			Ghost: 1,
			Steel: 1,
			Fire: 1,
			Water: 2,
			Grass: 2,
			Electric: 0.25,
			Psychic: 1,
			Ice: 2,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Rock: {
		damageTaken: {
			Normal: 0.5,
			Fighting: 2,
			Flying: 0.5,
			Poison: 0.5,
			Ground: 2,
			Rock: 1,
			Bug: 1,
			Ghost: 1,
			Steel: 2,
			Fire: 0.5,
			Water: 2,
			Grass: 2,
			Electric: 1,
			Psychic: 1,
			Ice: 1,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Bug: {
		damageTaken: {
			Normal: 1,
			Fighting: 0.5,
			Flying: 2,
			Poison: 1,
			Ground: 0.5,
			Rock: 2,
			Bug: 1,
			Ghost: 1,
			Steel: 1,
			Fire: 2,
			Water: 1,
			Grass: 0.5,
			Electric: 1,
			Psychic: 1,
			Ice: 1,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Ghost: {
		damageTaken: {
			Normal: 0.25,
			Fighting: 0.25,
			Flying: 1,
			Poison: 0.5,
			Ground: 1,
			Rock: 1,
			Bug: 0.5,
			Ghost: 2,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 1,
			Ice: 1,
			Dragon: 1,
			Dark: 2,
			Fairy: 1,
		},
    },
    Steel: {
		damageTaken: {
			Normal: 1,
			Fighting: 2,
			Flying: 0.5,
			Poison: 0.25,
			Ground: 2,
			Rock: 0.5,
			Bug: 0.5,
			Ghost: 1,
			Steel: 0.5,
			Fire: 2,
			Water: 1,
			Grass: 0.5,
			Electric: 1,
			Psychic: 0.5,
			Ice: 0.5,
			Dragon: 0.5,
			Dark: 1,
			Fairy: 0.5,
		},
    },
    Fire: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 1,
			Poison: 1,
			Ground: 2,
			Rock: 2,
			Bug: 0.5,
			Ghost: 1,
			Steel: 0.5,
			Fire: 0.5,
			Water: 2,
			Grass: 0.5,
			Electric: 1,
			Psychic: 1,
			Ice: 0.5,
			Dragon: 1,
			Dark: 1,
			Fairy: 0.5,
		},
    },
    Water: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 1,
			Bug: 1,
			Ghost: 1,
			Steel: 0.5,
			Fire: 0.5,
			Water: 0.5,
			Grass: 2,
			Electric: 2,
			Psychic: 1,
			Ice: 0.5,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Grass: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 2,
			Poison: 2,
			Ground: 0.5,
			Rock: 1,
			Bug: 2,
			Ghost: 1,
			Steel: 1,
			Fire: 2,
			Water: 0.5,
			Grass: 0.5,
			Electric: 0.5,
			Psychic: 1,
			Ice: 2,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Electric: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 0.5,
			Poison: 1,
			Ground: 2,
			Rock: 1,
			Bug: 1,
			Ghost: 1,
			Steel: 0.5,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 0.5,
			Psychic: 1,
			Ice: 1,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Psychic: {
		damageTaken: {
			Normal: 1,
			Fighting: 0.5,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 1,
			Bug: 2,
			Ghost: 2,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 0.5,
			Ice: 1,
			Dragon: 1,
			Dark: 2,
			Fairy: 1,
		},
    },
    Ice: {
		damageTaken: {
			Normal: 1,
			Fighting: 2,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 2,
			Bug: 1,
			Ghost: 1,
			Steel: 2,
			Fire: 2,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 1,
			Ice: 0.5,
			Dragon: 1,
			Dark: 1,
			Fairy: 1,
		},
    },
    Dragon: {
		damageTaken: {
			Normal: 1,
			Fighting: 1,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 1,
			Bug: 1,
			Ghost: 1,
			Steel: 1,
			Fire: 0.5,
			Water: 0.5,
			Grass: 0.5,
			Electric: 0.5,
			Psychic: 1,
			Ice: 2,
			Dragon: 2,
			Dark: 1,
			Fairy: 2,
		},
    },
    Dark: {
		damageTaken: {
			Normal: 1,
			Fighting: 2,
			Flying: 1,
			Poison: 1,
			Ground: 1,
			Rock: 1,
			Bug: 2,
			Ghost: 0.5,
			Steel: 1,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 0.25,
			Ice: 1,
			Dragon: 1,
			Dark: 0.5,
			Fairy: 2,
		},
    },
    Fairy: {
		damageTaken: {
			Normal: 1,
			Fighting: 0.5,
			Flying: 1,
			Poison: 2,
			Ground: 1,
			Rock: 1,
			Bug: 0.5,
			Ghost: 1,
			Steel: 2,
			Fire: 1,
			Water: 1,
			Grass: 1,
			Electric: 1,
			Psychic: 1,
			Ice: 1,
			Dragon: 0.25,
			Dark: 0.5,
			Fairy: 1,
		},
	},
};

export default typeChart;