import React, { useState } from 'react'

import Type from '../components/Type'
import TabElem from '../components/TabElem'
import MoveTableRow from '../components/MoveTableRow'
import IconLabel from '../components/IconLabel'
import Ability from '../components/Ability'
import SearchModule from '../components/SearchModule'
import RemoveButton from '../components/RemoveButton'

// eslint-disable-next-line
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

export default function PokemonPicker({
  pokemons = [],
  activeIndex = 0,
  setActiveIndex = () => {},
  setPokemons = () => {},
  setSelectedTypes = () => {}
}) {
  const activePokemon = pokemons[activeIndex]
  const [areMoveCollapsed, setAreMoveCollapsed] = useState(true)
  return (
    <section tw="relative py-4 bg-gray-300">
      <div tw="container mx-auto">
        <div tw="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
          <div tw="mt-12 px-6">
            <h4 tw="text-3xl font-semibold pb-6">Your pokémons</h4>
          </div>
          {pokemons.length <= 2 && (
            <div tw="px-6 py-4 relative w-full bg-gray-100 border-b  border-t border-gray-300">
              <SearchModule
                onSearchClick={(pokemonToAdd) => {
                  if (
                    !pokemons.find((e) => e.objectID === pokemonToAdd.objectID)
                  ) {
                    setPokemons([...pokemons, pokemonToAdd])
                    setActiveIndex(pokemons.length)
                    setSelectedTypes([])
                  }
                }}
                numLeft={3 - pokemons.length}
              />
            </div>
          )}
          <div tw="px-6">
            <div tw="w-full lg:w-4/12  lg:order-1">
              <nav tw="flex flex-col sm:flex-row">
                {pokemons.map((pokemon, index) => (
                  <TabElem
                    isActive={index === activeIndex}
                    label={pokemon.name}
                    key={`tabPkmn${index}`}
                    objKey={`tabPkmn${index}`}
                    onClick={() => setActiveIndex(index)}
                  />
                ))}
              </nav>
            </div>
            {activePokemon ? (
              <>
                <div tw="w-full flex justify-between items-start">
                  <div>
                    <div tw="flex flex-col lg:flex-row items-start mt-6">
                      <img
                        src={activePokemon.thumbnailLarge}
                        alt={`${activePokemon.name} large thumbnail`}
                        style={{height: "150px", width: "auto"}}
                      />
                      <div tw="ml-6">

                      <div tw="flex flex-col sm:flex-row items-end">
                        <span tw="text-xl font-semibold leading-normal text-gray-400">
                          #{activePokemon.num}
                        </span>
                        <h5 tw="text-lg font-semibold leading-normal  text-gray-600 px-2">
                          {activePokemon.name}
                        </h5>
                        <img
                          src={activePokemon.thumbnail}
                          alt={`${activePokemon.name} thumbnail`}
                        />
                      </div>

                      <div tw="flex flex-col sm:flex-row mt-2 mb-2">
                      {activePokemon.types.map((type) => (
                        <Type type={type} key={`type${type}`} />
                      ))}
                    </div>
                    <div tw="flex flex-col sm:flex-row items-baseline mb-2 mt-2 ">
                      <IconLabel
                        icon={'fa-ruler-vertical'}
                        label={activePokemon.heightm}
                      />
                      <IconLabel
                        icon={'fa-weight-hanging'}
                        label={activePokemon.weightkg}
                      />
                    </div>


                      </div>
                      
                    </div>

                    
                  </div>
                  <RemoveButton
                    label="remove"
                    icon="fa-trash"
                    onClick={() => {
                      setPokemons(
                        pokemons.filter(
                          (_, arrIndex) => arrIndex !== activeIndex
                        )
                      )
                      setActiveIndex(pokemons.length - 2)
                      setSelectedTypes([])
                    }}
                  />
                </div>
                <div tw="flex flex-col xl:flex-row mt-4 mb-6">
                  <div tw="w-full mr-10">
                    <div
                      tw="w-full flex justify-between items-start    pb-2 mt-4 border-b border-gray-300"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setAreMoveCollapsed(!areMoveCollapsed)}
                    >
                      <h5 tw="text-lg font-semibold leading-normal  text-gray-600">
                        Moves
                      </h5>
                      <div tw="text-gray-700">
                        <i
                          tw="text-lg"
                          className={`fas ${
                            areMoveCollapsed
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          }`}
                        ></i>
                      </div>
                    </div>

                    {!areMoveCollapsed && (
                      <div tw="block  mb-2 mt-4">
                        <table tw="items-center w-full bg-transparent border-collapse">
                          <thead>
                            <tr>
                              <th tw="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Move
                              </th>
                              <th tw="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Gen
                              </th>
                              <th tw="px-4 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Level
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activePokemon.moves.map((move, index) => (
                              <MoveTableRow move={move} key={`move${index}`} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  <div tw="w-full">
                    <div
                      tw="w-full flex justify-between items-start    pb-2 mt-4 mb-4 border-b border-gray-300"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setAreMoveCollapsed(!areMoveCollapsed)}
                    >
                      <h5 tw="text-lg font-semibold leading-normal  text-gray-600">
                        Abilities
                      </h5>
                      <div tw="text-gray-700">
                        <i
                          tw="text-lg"
                          className={`fas ${
                            areMoveCollapsed
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                          }`}
                        ></i>
                      </div>
                    </div>

                    {!areMoveCollapsed && (
                      <div tw="flex flex-wrap items-start">
                        {activePokemon.abilities.map((ability, index) => (
                          <Ability ability={ability} key={`ability${index}`} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div tw="w-full lg:w-4/12 py-20 lg:order-1">
                <p>Search and add up to 3 pokémons to your mix!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
