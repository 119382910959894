import React from 'react'

import {typeToHex} from "../tools/typeColour";

// eslint-disable-next-line
import tw, { css, styled } from 'twin.macro'
/** @jsxImportSource @emotion/react */



const TypeWrapper = styled.span(({ type, pointer }) => [
  tw`flex-grow-0 text-xs font-semibold inline-block py-1 px-2 text-white uppercase rounded last:mr-0 mr-1`,
  css`
    background-color: ${typeToHex(type)};
    cursor: ${pointer ? 'pointer' : 'default'};
    transition: all 0.3s ease;
  `,
  pointer && tw`hover:shadow`
])

export default function Type({ type, pointer = false, onClick = () => {} }) {
  return (
    <TypeWrapper type={type} pointer={pointer} onClick={onClick}>
      {type}
    </TypeWrapper>
  )
}
