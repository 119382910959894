import React from 'react'
import Type from '../components/Type'
import TypeChart from '../components/TypeChart'
import {
  allTypes,
  getPokemonTypes,
  uniqByType,
  countOccurences
} from '../tools/typeManagement'

// eslint-disable-next-line
import tw from 'twin.macro'
import AllAbilities from '../components/AllAbilities'
/** @jsxImportSource @emotion/react */

export default function PokemonMixer({
  pokemons,
  selectedTypes,
  setSelectedTypes
}) {
  const pokemonTypes = getPokemonTypes(pokemons)
  const availableTypes = pokemonTypes.filter(
    (pkmnType) =>
      selectedTypes.findIndex(
        (selectedType) => selectedType.label === pkmnType.label
      ) < 0
  )

  const getValidityErrors = () => {
    let res = ''
    // No pokemon types
    if (!selectedTypes.find((e) => e.from !== 'blend')) {
      return 'You must select a type from a pokémon!'
    }

    // More than 2 instances of a type

    //One type per pokemon needed
    const selectedIds = pokemons
      .map((pokemon) => ({
        objectID: pokemon.objectID,
        type: pokemon.types
      })) // get all types
      .filter((item, pos, self) => self.indexOf(item) === pos) // filter dupes
    const fromIds = selectedTypes
      .filter((e) => e.from !== 'blend') // filter blends
      .map((f) => f.label) // extract type
      .filter((item, pos, self) => self.indexOf(item) === pos) // filter dupes

    console.log({
      selectedIds,
      fromIds
    })

    if (selectedIds.length !== fromIds.length) {
      selectedIds.forEach((elem) => {
        let isAtLeastOneTypeSelected = false
        elem.type.forEach((type) => {
          if (fromIds.indexOf(type) >= 0) {
            isAtLeastOneTypeSelected = true
          }
        })
        if (!isAtLeastOneTypeSelected) {
          res = `You must select a type from ${
            pokemons.find((e) => e.objectID === elem.objectID).name
          } : ${elem.type.toString()}`
        }
      })
    }

    const allTypes = selectedTypes.map((type) => type.label)
    allTypes.forEach((type) => {
      const count = countOccurences(allTypes, (e) => e === type)
      if (count.true > 2) {
        res = `Type "${type}" cannot be used more than 2 times`
      }
    })
    return res
  }

  const isMixFull = () => {
    return selectedTypes.length === 4
  }

  const selectType = (type) => {
    if (selectedTypes.length <= 3 && !isMixFull()) {
      setSelectedTypes([...selectedTypes, type])
    }
  }

  const errors = getValidityErrors()

  return (
    <div tw="relative bg-gray-300 pb-6">
      <div tw="container mx-auto px-4">
        <h4 tw="text-3xl font-semibold py-4">Your mix</h4>
        <div tw="flex flex-wrap items-start pb-4">
          {pokemons.map((pokemon) => (
            <button
              key={pokemon.objectID}
              tw=" flex items-center bg-gray-100 text-black focus:text-blue-500 hover:text-blue-500 active:bg-white font-bold uppercase text-sm pl-10 pr-4 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-3"
              type="button"
              style={{
                transition: 'all .15s ease',
                backgroundImage: `url(${pokemon.thumbnail})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '.4em .2em',
                backgroundSize: 'auto 2em'
              }}
            >
              {pokemon.name}
            </button>
          ))}
          {pokemons.length === 0 && <span>No pokemon selected.</span>}
        </div>
        <span tw="block uppercase text-gray-700 text-xs font-bold mb-2 border-t border-gray-400 pt-6">
          Available types
        </span>
        <div tw="flex flex-col sm:flex-row mt-4 mb-6">
          {availableTypes.length > 0 ? (
            uniqByType(availableTypes).map((type) => (
              <Type
                type={type.label}
                key={`type${type.from}${type.label}`}
                pointer={!isMixFull()}
                onClick={() => selectType(type)}
              />
            ))
          ) : (
            <span>
              {pokemons.length > 0
                ? 'No available type'
                : 'No pokemon selected.'}
            </span>
          )}
        </div>
        <span tw="block uppercase text-gray-700 text-xs font-bold mb-2 border-t border-gray-400 pt-6">
          Additional types (blend trait)
        </span>
        <div tw="flex flex-col sm:flex-row mt-4 mb-6">
          {allTypes.map((type) => (
            <Type
              type={type}
              key={`typeBlend${type}`}
              pointer={!isMixFull()}
              onClick={() =>
                selectType({
                  label: type,
                  from: 'blend'
                })
              }
            />
          ))}
        </div>
        <h4 tw="text-xl font-semibold py-4 border-t border-gray-400">
          Chosen types
        </h4>
        <div tw="flex flex-col xl:flex-row mt-4 mb-6">
          {selectedTypes.length > 0 ? (
            selectedTypes.map((type, index) => (
              <Type
                type={type.label}
                key={`typeSelected${index}${type.label}`}
                pointer
                onClick={() =>
                  setSelectedTypes(
                    selectedTypes.filter((_, arrIndex) => arrIndex !== index)
                  )
                }
              />
            ))
          ) : (
            <span>No type selected. Select at least one!</span>
          )}
        </div>
        {selectedTypes.length > 0 && errors !== '' && (
          <p tw="text-xs font-semibold inline-block text-red-500 uppercase">
            {errors}
          </p>
        )}
        {selectedTypes.length > 0 && errors === '' && (
          <div tw="flex flex-col md:flex-row">
            <TypeChart selectedTypes={selectedTypes} />
            <AllAbilities pokemons={pokemons} />
          </div>
        )}
      </div>
    </div>
  )
}
