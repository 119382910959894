import typeChart from "./typeChart"

export const allTypes = [
    'Normal',
    'Fire',
    'Water',
    'Electric',
    'Grass',
    'Ice',
    'Fighting',
    'Poison',
    'Ground',
    'Flying',
    'Psychic',
    'Bug',
    'Rock',
    'Ghost',
    'Dragon',
    'Dark',
    'Steel',
    'Fairy'
  ]
  
  export const getEffectiveness = (typeSource, target) => {
    let totalTypeMod = 1
    if (Array.isArray(target)) {
      for (const type of target) {
        totalTypeMod = getEffectiveness(typeSource, type) * totalTypeMod
      }
      return totalTypeMod
    }
    const typeData = typeChart[target]
    if (!typeData) return 0
    return typeData.damageTaken[typeSource]
  }
  
  export const getPokemonTypes = (pokemons) => {
    const rawTypes = []
    pokemons.forEach((pokemon) => {
      pokemon.types.forEach((type) =>
        rawTypes.push({
          label: type,
          from: pokemon.objectID
        })
      )
    })
    return rawTypes
  }
  
  export const uniqByType = (data) => {
    const resArr = []
    data.filter((item) => {
      const i = resArr.findIndex((x) => x.label === item.label)
      if (i <= -1) {
        resArr.push(item)
      }
      return null
    })
    return resArr
  }
  
  export const countOccurences = (ary, classifier) => {
    classifier = classifier || String
    return ary.reduce((counter, item) => {
      var p = classifier(item)
      counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1
      return counter
    }, {})
  }