import React from 'react'

// eslint-disable-next-line
import tw, { styled } from 'twin.macro'
/** @jsxImportSource @emotion/react */

const AbilityHiddenWrapper = styled.div(({ isHidden = false }) => [
  tw`text-white text-center inline-flex items-center justify-center w-8 h-8 mb-2 rounded-full`,
  isHidden && tw`shadow-lg bg-red-400`
])

export default function Ability({ ability }) {
  return (
    <div tw="lg:pt-2 pt-2 w-full md:w-4/12 px-4 text-center ">
      <div tw="relative flex flex-col min-w-0 break-words bg-white w-full mb-2">
        <div tw="flex-auto">
          <AbilityHiddenWrapper isHidden={ability.hidden}>
            <i className="fas fa-award"></i>
          </AbilityHiddenWrapper>
          <h6 tw="text-lg font-semibold">{ability.name}</h6>
          <p tw="mt-2 mb-4 text-gray-600">{ability.description}</p>
        </div>
      </div>
    </div>
  )
}
